import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormContextSetup, FormContextSigningOverride } from '@property-folders/components/form-gen-util/yjsStore';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import {
  FolderType,
  FormCode,
  FormCodeUnion,
  FormInstance,
  FormInstanceSigning,
  FormInstanceUploadFileItem,
  FormSigningState,
  ManifestType,
  MaterialisedPropertyData,
  Maybe,
  TransactionMetaData
} from '@property-folders/contract';
import './FormInput.scss';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { Allotment, LayoutPriority } from 'allotment';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { Button, Card, Col, Container, FloatingLabel, Row } from 'react-bootstrap';
import { useLightweightTransaction, useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { FormUtil, generateInitiator } from '@property-folders/common/util/form';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useStore } from 'react-redux';
import clsJn from '@property-folders/common/util/classNameJoin';
import {
  SigningFieldsConfiguration
} from '@property-folders/components/dragged-components/signing/fieldConfiguration/SigningFieldsConfiguration';
import { SigningConfiguration } from '@property-folders/components/dragged-components/signing/SigningConfiguration';
import { FormContext } from '@property-folders/components/context/FormContext';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { useBrandConfig, useEntities } from '@property-folders/components/hooks/useEntity';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import { SigningSession } from '@property-folders/components/dragged-components/signing/SigningSession';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { usePdfPreviewUrl } from '@property-folders/components/dragged-components/subscriptionForms/hooks';
import { ExtractedField } from '@property-folders/common/util/pdf/types';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import {
  prepareForSigningCustomPdf
} from '@property-folders/components/dragged-components/Wizard/prepareForSigningHandlers';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { ensureV2Upload, upgradeFormUploadToV2 } from '@property-folders/common/yjs-schema/property';
import {
  CollectionEditor,
  EditorListChildProps
} from '@property-folders/components/dragged-components/form/CollectionEditor';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { useDropzone } from 'react-dropzone';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { v4 } from 'uuid';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { clamp } from 'lodash';
import { usePdfWorker } from '@property-folders/components/hooks/usePdfWorker';
import { CollectionRemoveButton } from '@property-folders/components/dragged-components/form/CollectionRemoveButton';
import { FormatUploaderInfo } from '@property-folders/components/display/FormatUploaderInfo';
import { propertyMeta } from '@property-folders/contract/yjs-schema/model/field';
import { useNavigate } from 'react-router-dom';
import { SharingWizardStepPage } from '@property-folders/components/dragged-components/Wizard/SharingWizardStepPage';
import { StandaloneAgencyWizardStepPage } from '@property-folders/components/dragged-components/Wizard/StandaloneAgencyWizardStepPage';
import { useFeatureFlags } from '@property-folders/components/hooks/useFeatureFlags';
import { useQpdfWorker } from '@property-folders/components/hooks/useQpdfWorker';

const FORM_CODE = FormCode.UploadedDocument;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  transactionRules: { _type: 'Map' },
  metaRules: propertyMeta
};

enum AllotmentPaneMode {
  Both = 0,
  Content = 1,
  Preview = 2
}

export const UploadedDocumentPage = (props: TransactionFormProps) => {
  const { value: folderType } = useLightweightTransaction<FolderType>({ myPath: 'folderType', bindToMetaKey: true });
  const formId = props.formId;
  const updatedBreadcrumb = useMemo(() => {
    if (folderType === FolderType.MyFile) {
      return [
        { label: 'All Documents', href: '/forms' },
        { label: '', href: '' }
      ];
    }

    return [...(props.breadcrumbs ?? []), {
      label: 'All Documents',
      href: `${props.breadcrumbs[props.breadcrumbs.length - 1]?.href}/documents`
    }, { label: FORM_LABEL }];
  }, [props.breadcrumbs, folderType]);
  return <FormContextSetup formId={formId} base={FORM_CONTEXT}>
    <UploadedDocumentInner {...props} breadcrumbs={updatedBreadcrumb} />
  </FormContextSetup>;
};

export const UploadedDocumentInner = ({ breadcrumbs, entityLogoLoadedUri }: TransactionFormProps): JSX.Element => {
  const splitEnabled = useMediaQuery({ minWidth: BP_MINIMA.xl });
  const { value: folderType } = useLightweightTransaction<FolderType>({ myPath: 'folderType', bindToMetaKey: true });
  const { ydoc, transactionRootKey, transactionMetaRootKey, docName: propertyId } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);
  const { value: form, fullPath: formPath } = useTransactionField<FormInstance>({
    parentPath: FormUtil.getFormPath(formCode as FormCodeUnion, formId),
    bindToMetaKey: true
  });
  const { updateDraft: updateForm } = useYdocBinder<FormInstance>({
    path: formPath,
    bindToMetaKey: true
  });

  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const {
    bindState: metaBindState,
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const store = useStore();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { value: signing } = useLightweightTransaction<FormInstanceSigning>({
    parentPath: FormUtil.getFormPath(FORM_CODE, formId),
    myPath: 'signing',
    bindToMetaKey: true,
    ydocForceKey: transactionMetaRootKey
  });
  const [unfilledFields, setUnfilledFields] = useState<ExtractedField[]>([]);
  const formConfigs = useBrandConfig();
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const brand = formConfigs.getFormConfig(meta?.entity?.id);
  const { pdfPreviewUrl: previewUrls, pdfLoadErrorMessage } = usePdfPreviewUrl({
    form,
    formCode,
    formId,
    yDoc: ydoc,
    unsetFieldsHandler: fields => {
      setUnfilledFields(cur => {
        if (cur.length === 0 && fields.length === 0) {
          return cur;
        }
        return fields;
      });
    },
    brand
  });
  const { instance: fileSync } = useContext(FileSyncContext);
  const memberEntities = useEntities();
  const localEntity = memberEntities && meta?.entity?.id ? memberEntities?.[meta.entity.id] : null;
  const isCoverSheet = Boolean(form?.upload?.editableAsCoverFor);
  const allowSigning = !isCoverSheet;
  const navigate = useNavigate();

  const {
    signingSessionWizardPropsForSidebar,
    showConfiguration,
    showSigningSession,
    partyGroups,
    serveToPurchaserProps,
    signingMainProps
  } = useSigningNavProps({ signing, formCode });

  const signingState = signing?.state || FormSigningState.None;
  const customiseScreen = signing?.customiseScreen;

  const handleArchive = () => {
    if (folderType === FolderType.MyFile) {
      metaBinder?.update(draft => {
        const form = draft.formStates?.[FormCode.UploadedDocument]?.instances?.at(0);
        if (!form) {
          console.warn('form not found');
          return;
        }
        if (draft.archived) {
          delete draft.archived;
          form.archived = false;
          form.dataModified = Date.now();
        } else {
          draft.archived = {
            archivedByAgentId: sessionInfo?.agentId,
            archivedDate: (new Date()).toISOString()
          };
          form.archived = true;
          form.dataModified = Date.now();
        }
      });
    } else {
      updateForm?.(draft => {
        if (!draft) return;
        draft.archived = !draft.archived;
        draft.dataModified = Date.now();
      });
    }
  };

  const startSigningHandler = () => {
    if (!ydoc) return;
    const formDal = new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey);
    FormUtil.transitionSigningState({
      store,
      formCode: FORM_CODE,
      formId: formId,
      metaBinder,
      dataBinder,
      sessionInfo,
      entitySigningOpts: localEntity.signingOptions,
      getSublineageData: sublineageId => formDal.getSublineageData(sublineageId)
    }, {
      to: FormSigningState.Configuring
    });
  };

  const cancelSigningHandler = () => {
    console.log('transition signing to configuring');
    FormUtil.transitionSigningState({
      store,
      formCode: FORM_CODE,
      formId: formId,
      metaBinder,
      dataBinder,
      sessionInfo
    }, {
      to: FormSigningState.None
    });
  };

  const setCustomisationScreenHandler = (toScreen: 'general' | 'fields') => {
    metaBinder?.update(draft => {
      const signing = FormUtil.getSigning(formCode, formId, draft);
      if (signing?.state !== FormSigningState.Configuring) return;

      switch (toScreen) {
        case 'fields':
          signing.customiseScreen = 'fields';
          FormUtil.clearUnnecessaryCustomFields(signing);
          break;
        case 'general':
          delete signing.customiseScreen;
          break;
      }
    });
  };

  const { instance: yManagerInstance } = useContext(YManagerContext);
  const getCurrentUserPrefs = useCallback(()=>yManagerInstance?.getUserPrefs()?.doc.getMap(UserPreferencesRootKey.Main).toJSON(), [yManagerInstance]);

  const [prepareForSigningError, setPrepareForSigningError] = useState('');
  const worker = usePdfWorker();
  const prepareForSigningHandler = async () => {
    if (!ydoc) return;
    if (!meta) return;
    if (!memberEntities || !localEntity) return;

    setPrepareForSigningError('');
    try {
      const { error } = await prepareForSigningCustomPdf({
        formId,
        formCode: formCode as FormCodeUnion,
        sessionInfo,
        store,
        fileSync,
        dal: new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey),
        initiator: generateInitiator(meta, sessionInfo, localEntity),
        entitySigningOpts: localEntity.signingOptions,
        getUserPrefsData: getCurrentUserPrefs,
        memberEntities,
        ydoc,
        worker,
        brand
      });

      if (error) {
        setPrepareForSigningError(error.message);
      }

    } catch (err: unknown) {
      console.error(err);
      setPrepareForSigningError('Unexpected error');
    }
  };

  const [allotmentPaneMode, setAllotmentPaneMode] = useState(splitEnabled ? AllotmentPaneMode.Both : AllotmentPaneMode.Content);
  const onVisibleChange = useCallback((changedIndex: number, changedNowVisible: boolean) => {
    if (!changedNowVisible) {
      return setAllotmentPaneMode(changedIndex === 0 ? AllotmentPaneMode.Preview : AllotmentPaneMode.Content);
    }

    if (splitEnabled) {
      return setAllotmentPaneMode(AllotmentPaneMode.Both);
    }

    setAllotmentPaneMode(changedIndex === 0 ? AllotmentPaneMode.Content : AllotmentPaneMode.Preview);
  }, [splitEnabled]);

  useEffect(() => {
    if (splitEnabled) {
      setAllotmentPaneMode(AllotmentPaneMode.Both);
    } else {
      setAllotmentPaneMode(AllotmentPaneMode.Content);
    }
  }, [splitEnabled]);

  const ff = useFeatureFlags();
  const teamManagementVisible = Boolean(localEntity?.teamManagementVisible);

  const showContentPane = allotmentPaneMode === AllotmentPaneMode.Both || allotmentPaneMode === AllotmentPaneMode.Content;
  const showPreviewPane = allotmentPaneMode === AllotmentPaneMode.Both || allotmentPaneMode === AllotmentPaneMode.Preview;

  const afterTitle = <>
    {signingState === FormSigningState.None &&
      <Button variant='outline-secondary' onClick={handleArchive}>{form?.archived ? 'Unarchive' : 'Archive'}</Button>}
    {signingState === FormSigningState.None && isCoverSheet &&
      <Button variant='outline-secondary' onClick={() => navigate('cover-config')}>Cover Sheet Config</Button>}
    {allowSigning && signingState === FormSigningState.None &&
      <Button variant='primary' className='d-none d-md-block' disabled={form?.archived || !form?.upload?.name} onClick={startSigningHandler}>Signing</Button>}
    {signingState === FormSigningState.Configuring &&
      <Button variant='outline-secondary' onClick={cancelSigningHandler}>Cancel Signing</Button>}
    {signingState === FormSigningState.Configuring && customiseScreen === 'fields' &&
      <Button
        variant='outline-secondary'
        onClick={() => setCustomisationScreenHandler('general')}
      >Previous</Button>}
    {signingState === FormSigningState.Configuring && customiseScreen === 'fields' &&
        <AsyncButton onClick={() => prepareForSigningHandler()} processingLabel={'Preparing document'}>Submit for Signing</AsyncButton>}
  </>;

  if (!propertyId) {
    return <></>;
  }

  const title = `${form?.upload?.name || ''} ${form?.archived ? '(archived)' : ''}`;

  if (showConfiguration) {
    return <ContentTitler
      breadcrumbs={breadcrumbs}
      title={title}
      afterBreadcrumbs={<>{prepareForSigningError && <span className='text-danger'>{prepareForSigningError}</span>}</>}
      afterTitle={afterTitle}
      flex={true}
    >
      <FormContextSigningOverride>
        {signing?.customiseScreen === 'fields'
          ? <SigningFieldsConfiguration
            propertyId={propertyId}
            formCode={formCode}
            formId={formId}
            entityLogoLoadedUri={entityLogoLoadedUri}
            ydoc={ydoc}
            yRootKey={transactionRootKey}
            yMetaRootKey={transactionMetaRootKey}
            prepareForSigning={prepareForSigningHandler}
            prepareForCustomising={() => setCustomisationScreenHandler('fields')}
          />
          : <SigningConfiguration
            propertyId={propertyId}
            formCode={formCode}
            formId={formId}
            entityLogoLoadedUri={entityLogoLoadedUri}
            ydoc={ydoc}
            yRootKey={transactionRootKey}
            yMetaRootKey={transactionMetaRootKey}
            prepareForSigning={prepareForSigningHandler}
            prepareForCustomising={() => setCustomisationScreenHandler('fields')}
          />}
      </FormContextSigningOverride>
    </ContentTitler>;
  }

  return (
    <div className={clsJn('alot-container position-relative h-100 w-100 d-flex')}>
      <Allotment snap onVisibleChange={onVisibleChange}>
        <Allotment.Pane minSize={300} preferredSize={760} priority={LayoutPriority.High} visible={showContentPane}>
          <ContentTitler
            title={title}
            flex={true}
            scroll={true}
            afterTitle={afterTitle}
            breadcrumbs={breadcrumbs}
            afterBreadcrumbs={allotmentPaneMode !== AllotmentPaneMode.Both
              ? <Button
                variant='link'
                title='Preview'
                size='sm'
                className='px-0 py-0 border-0 bg-transparent link-secondary'
                onClick={()=> splitEnabled
                  ? setAllotmentPaneMode(AllotmentPaneMode.Both)
                  : setAllotmentPaneMode(AllotmentPaneMode.Preview)}
              >
                Preview
              </Button>
              : <></>}
          >
            {showSigningSession
              ? <SigningSession
                formCode={formCode as FormCodeUnion}
                formId={formId}
                ydoc={ydoc}
                onVoid={cancelSigningHandler}
                wizardSectionProps={signingSessionWizardPropsForSidebar}
                dataRootKey={PropertyRootKey.Data}
                metaRootKey={PropertyRootKey.Meta}
                partyGroups={partyGroups}
                serveToPurchaserProps={serveToPurchaserProps}
                signingMainProps={signingMainProps}
                showPartyColours={!!unfilledFields.length}
              />
              : <EditCards formPath={formPath} />}
            {folderType === FolderType.MyFile && <StandaloneAgencyWizardStepPage />}
            {folderType === FolderType.MyFile && teamManagementVisible && <SharingWizardStepPage
              name='sharing'
              label='Sharing'
              icon='sharing'
            />}
          </ContentTitler>
        </Allotment.Pane>
        <Allotment.Pane visible={showPreviewPane}>
          {<SetupPdfLoadStateContext>
            <PDFViewer
              pdfUrl={previewUrls}
              allowPrint={true}
              filename={form?.upload?.name}
              renderTextLayer={signingState === FormSigningState.None}
              bookmark={''}
              contextDependentLoadingMessage={pdfLoadErrorMessage}
              pageWrapElement={({ children, pageIndex, dimensions }) => <div key={`pw_${pageIndex}`} className='mt-3 position-relative' style={{ width: 'min-content', marginInline: 'auto' }}>
                {children}
              </div>}
              toolbarRight={<>
                {allotmentPaneMode !== AllotmentPaneMode.Content
                  ? <Button
                    variant='secondary'
                    onClick={() => {
                      setAllotmentPaneMode(AllotmentPaneMode.Content);
                    }}
                  >
                    <Icon name='close' />
                  </Button>
                  : undefined}
              </>}
            />
          </SetupPdfLoadStateContext>}
        </Allotment.Pane>
      </Allotment>
    </div>
  );
};

const dragAndDropConfig = {
  handleClass: 'align-self-start me-3',
  draggableClass: 'mb-2'
};

function EditCards({ formPath }: { formPath: string }) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { docName: propertyId, ydoc } = useContext(YjsDocContext);
  const { instance: fileSync } = useContext(FileSyncContext);
  const { value: form } = useTransactionField<FormInstance>({
    parentPath: formPath,
    bindToMetaKey: true
  });
  const { updateDraft: updateForm } = useYdocBinder<FormInstance>({
    path: formPath,
    bindToMetaKey: true
  });
  const { value: headline, handleUpdate: updateHeadline } = useTransactionField<string>({ myPath: 'headline' });
  const { value: folderType } = useLightweightTransaction<FolderType>({ parentPath: 'folderType', bindToMetaKey: true });
  const qpdfWorker = useQpdfWorker();

  useEffect(() => {
    if (!form?.upload?.name) return;
    if (form.upload.name === headline) return;
    if (folderType !== FolderType.MyFile) return;

    updateHeadline(form.upload.name, true);
  }, [headline, form?.upload?.name]);

  const upgradeUploadVersion = () => updateForm?.(draft => upgradeFormUploadToV2(draft));

  useEffect(() => {
    if (!form?.upload) return;
    if (form.upload.v === 2) return;
    if (form.signing && ![FormSigningState.None, FormSigningState.Configuring, undefined].includes(form.signing.state)) return;
    console.log('auto upgrade to upload v2');
    updateForm?.(draft => upgradeFormUploadToV2(draft));
  }, [form?.upload?.v, form?.signing?.state]);

  const handleDrop = (files: File[], idx?: number) => {
    (async () => {
      if (!propertyId) return;
      if (!ydoc) return;
      if (!fileSync) return;
      if (!updateForm) return;

      const processed: [Blob, string, string][] = [];
      while (files.length) {
        const file = files.pop();
        if (!file) continue;
        const decrypted = await qpdfWorker.decrypt({ pdf: await file.arrayBuffer() });
        const pdf = (await processPdf(decrypted));
        if (!pdf?.pdf || pdf.isEncrypted) continue;

        processed.push([new Blob([pdf.pdf], { type: file.type }), v4(), file.name.replace(/\.[^/.]+$/, '')]);
      }

      if (!processed.length) return;

      for (const [file, fileId] of processed) {
        await FileStorage.write(
          fileId,
          FileType.PropertyFile,
          file.type,
          file,
          StorageItemSyncStatus.PendingUpload,
          {
            propertyFile: {
              propertyId,
              formId: form.id,
              formCode: form.formCode
            }
          },
          { store: fileSync.store, ydoc },
          { manifestType: ManifestType.None },
          undefined
        );
      }

      const [_, __, formName] = processed[0];
      updateForm(draft => {
        const upload = ensureV2Upload(draft, formName);
        const start = clamp(idx ?? upload.files.length, 0, upload.files.length);
        upload.files.splice(start, 0, ...(processed.map<FormInstanceUploadFileItem>(([file, fileId, name]) => ({
          id: fileId,
          contentType: file.type,
          name,
          size: file.size,
          uploader: sessionInfo?.agentUuid ? {
            id: sessionInfo.agentUuid,
            linkedSalespersonId: sessionInfo.agentId,
            name: sessionInfo?.name,
            email: sessionInfo?.email
          } : undefined,
          created: Date.now()
        }))));
      });

      FileSync.triggerSync(fileSync);
    })();
  };

  const handleDelete = (deleted: Maybe<FormInstanceUploadFileItem>) => {
    if (!deleted?.id) return;
    FileStorage.delete(deleted.id)
      .then(() => console.log('done', deleted))
      .catch(console.error);
  };

  const { inputRef, getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    accept: { 'application/pdf': [] },
    multiple: true
  });

  return <>
    <Card className='card wiz-card bg-white mx-0 mx-sm-2 mx-md-4 shadow'>
      <Card.Header className={'bg-white accordion-card-header card-header d-flex flex-row justify-content-between'}>
        <div className='title'>
          <span className='fs-3'>Envelope</span>
        </div>
        <div>
          {form?.upload?.v !== 2 && <Button
            variant='outline-secondary'
            onClick={upgradeUploadVersion}>Upgrade Data Structure</Button>}
        </div>
      </Card.Header>
      <Container className='p-3'>
        <Row>
          <Col>
            <WrField.Text
              parentPath={formPath}
              myPath='upload.name'
              bindToMetaKey={true}
              name='name'
              label='Name'
              extraErrors={form?.upload?.name ? undefined : ['Name is required']}
            />
          </Col>
        </Row>
      </Container>
    </Card>
    {form?.upload?.v === 2
      ? <Card {...getRootProps({ className: clsJn('card wiz-card bg-white mx-0 mx-sm-2 mx-md-4 shadow', isDragAccept && 'drop-target drop-accept') })}>
        <Card.Header className={'bg-white accordion-card-header card-header d-flex flex-row justify-content-between'}>
          <div className='title'>
            <span className='fs-3'>Documents</span>
          </div>
          <div>
            <Button variant='outline-secondary' onClick={() => {
              if (inputRef?.current) {
                inputRef.current.value = '';
                inputRef.current.click();
              }
            }}>Add Document</Button>
          </div>
        </Card.Header>
        <Container className='p-3'>
          <input {...getInputProps({ className: 'd-none', type: 'file', accept: '.pdf', multiple: true })} />
          <CollectionEditor
            allowAdd={false}
            childItemRenderer={EnvelopeDocumentEditor}
            parentPath={formPath}
            myPath={'upload.files'}
            minimum={1}
            onDelete={handleDelete}
            bindToMetaKey={true}
            allowDragAndDrop={true}
            dragAndDropConfig={dragAndDropConfig}
            childProps={{
              onDrop: handleDrop,
              timeZone: sessionInfo?.timeZone
            }}
            itemNoun='Document'
          />
        </Container>
      </Card>
      : <Card className='card wiz-card bg-white mx-0 mx-sm-2 mx-md-4 shadow'>
        <Card.Header className={'bg-white accordion-card-header card-header d-flex flex-row justify-content-between'}>
          <div className='title'>
            <span className='fs-3'>Documents</span>
          </div>
        </Card.Header>
        <Container className='p-3'>
          <Row>
            <Col>
              <FloatingLabel label='Name' className='w-100'>
                <span>{form?.upload?.name}</span>
              </FloatingLabel>
            </Col>
          </Row>
        </Container>
      </Card>}
  </>;
}

function EnvelopeDocumentEditor(props: TransactionConsumerProps & EditorListChildProps & { timeZone?: string }) {
  const { value: fileItem, fullPath, handleRemove } = useTransactionField<FormInstanceUploadFileItem>({
    parentPath: props.parentPath,
    myPath: props.myPath,
    bindToMetaKey: true
  });

  return <div className={'w-100'} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr min-content' }}>
    <div style={{ gridColumn: '1 / 3' }}>
      <WrField.Control
        bindToMetaKey={true}
        parentPath={fullPath}
        myPath={'name'}
        name={'name'}
        className={'fs-5'}
      />
    </div>
    <div style={{ justifySelf: 'center' }}>
      <CollectionRemoveButton
        removable={props.removable ?? true}
        onRemove={() => {
          handleRemove();
          props.onDelete?.(fileItem);
        }}
        className={'ms-3'}
      />
    </div>
    <div>
      <WrField.BoolCheck
        bindToMetaKey={true}
        parentPath={fullPath}
        myPath={'cover'}
        name={'cover'}
        label={'Show coversheet'}
        className={'fs-5'}
      />
    </div>
    <div>
      <FormatUploaderInfo
        name={fileItem?.uploader?.name}
        size={fileItem?.size}
        timestamp={fileItem?.created}
        timeZone={props.timeZone}
      />
    </div>
  </div>;
}
