import { EntityLargeScreenPreviewClickHandler, EntitySmallScreenPreviewButton } from '~/pages/settings/PreviewHandlers';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { reaformsOrange } from '@property-folders/common/visual';
import {
  EmailPreview,
  useAgentSessionPreviewEmailContact
} from '@property-folders/components/display/EmailPreview';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EntityBrandEmailConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { Col, Row } from 'react-bootstrap';
import { useContext } from 'react';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { NodeEmailSettingsImageUpload } from '../NodeEmailSettingsImageUpload';

export enum EmailSettingsPreviewKey {
  Email = 'email'
}

export function EmailSettingsEdit({ onPreviewKey }: { onPreviewKey: (key: string) => void }) {
  const { entityUuid } = useContext(EntitySettingsContext);
  return <div>
    <EntityLargeScreenPreviewClickHandler onPreviewKey={onPreviewKey} previewKey={EmailSettingsPreviewKey.Email}>
      <WizardStepPage
        name='emailColours'
        label='Email Style'
        icon='email'
        headerContent={<EntitySmallScreenPreviewButton onPreviewKey={onPreviewKey} previewKey={EmailSettingsPreviewKey.Email}/>}
      >
        <Row>
          <Col size={12}>
            <h4>
            Button
            </h4>
          </Col>
        </Row>
        <Row>
          <Col size={6}><WrField.ColourPicker name='Text' parentPath='brand.email.button' myPath='foregroundColour' defaultValue={reaformsOrange} /></Col>
          <Col size={6}><WrField.ColourPicker name='Background' parentPath='brand.email.button' myPath='backgroundColour' defaultValue={'#ffffff'} /></Col>
        </Row>
        <Row className='mt-3'>
          <Col size={12}>
            <h4>
            Content
            </h4>
          </Col>
        </Row>
        <Row>
          <Col size={6}><WrField.ColourPicker name='Text' parentPath='brand.email.content' myPath='foregroundColour' defaultValue={'#ffffff'} /></Col>
          <Col size={6}><WrField.ColourPicker name='Background' parentPath='brand.email.content' myPath='backgroundColour' defaultValue={reaformsOrange} /></Col>
        </Row>
        {entityUuid && <>
          <Row className='mt-3'>
            <Col size={12}><h4>Logo</h4></Col>
          </Row>
          <Row>
            <Col size={12}>
              <NodeEmailSettingsImageUpload />
            </Col>
          </Row>
        </>}
      </WizardStepPage>
    </EntityLargeScreenPreviewClickHandler>
  </div>;
}

export function EmailSettingsPreview({ previewKey }: {previewKey: string}) {
  switch (previewKey) {
    case EmailSettingsPreviewKey.Email:
    default:
      return <DefaultEmailPreview />;
  }
}

function DefaultEmailPreview() {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const { value: brand } = useLightweightTransaction<EntityBrandEmailConfig>({ parentPath: 'brand.email' });
  const contact = useAgentSessionPreviewEmailContact();
  return <EmailPreview
    brand={{
      logoUrl: (entityPhpInfo?.urlEmailLogo ?? entityPhpInfo?.urlLogo) || '',
      logoDims: undefined,
      button: brand?.button ?? {
        foregroundColour: reaformsOrange,
        backgroundColour: '#ffffff'
      },
      content: brand?.content ?? {
        foregroundColour: '#ffffff',
        backgroundColour: reaformsOrange
      }
    }}
    brandedContent={{
      text: `${contact.name} sent you a Document to review and sign.`,
      button: 'REVIEW'
    }}
    contact={contact}
    subject='Please sign: Document for 1 Example Way, Adelaide, SA 5000'
  />;
}
