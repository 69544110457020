import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { FormCode, FormInstance, MaterialisedPropertyData, SigningPartySourceType, sourceTypeGroup, TransactionMetaData } from '@property-folders/contract';
import './forms/FormInput.scss';
import { Allotment, LayoutPriority } from 'allotment';

import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { FormTypes, determinePreviewFiles } from '@property-folders/common/yjs-schema/property/form';
import { FormContextSetup } from '@property-folders/components/form-gen-util/yjsStore';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field';
import { WizardDisplayContext, WizardDisplayContextType } from '@property-folders/components/context/WizardContexts';
import { FormUserInteractionContext } from '@property-folders/components/context/FormUserInteractionContext';
import { LineageContext } from '@property-folders/components/hooks/useVariation';
import { FormUtil } from '@property-folders/common/util/form';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { GroupedPartiesRow, signingPartyForCard } from '@property-folders/components/dragged-components/signing/SigningPartyConfiguration';
import { Predicate } from '@property-folders/common/predicate';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { useLiveQuery } from 'dexie-react-hooks';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { SigningGeneralConfiguration } from '@property-folders/components/dragged-components/signing/SigningGeneralConfiguration';
import { SigningConfigurationNextButton } from '@property-folders/components/dragged-components/signing/SigningConfigurationNextButton';
import { Row, Col } from 'react-bootstrap';
import { ERROR_FOCUS_PREFIX, canonicalisers } from '@property-folders/common/util/formatting';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useMatches, useNavigate } from 'react-router-dom';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { cloneDeep } from 'lodash';
import { Agent, FormSigningState, PropertyRootKey, SigningInitiator, SigningPartyType } from '@property-folders/contract/yjs-schema/property';
import { useSigningValidation } from '@property-folders/components/dragged-components/signing/SigningConfiguration';
import { useForm } from '@property-folders/components/hooks/useForm';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';

const FORM_CODE = FormCode.RSC_ContractOfSale;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const TRANSACTION_RULES = propertyFolder;

const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  printTitle: FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  formRules: {},
  transactionRules: TRANSACTION_RULES,
  metaRules: propertyMeta,
  fieldGroups: {},
  reportMissing: ()=>false
};

export const VendorToSign = (props: TransactionFormProps) => {
  const formId = props.formId;
  const { ydoc, transactionMetaRootKey, transactionRootKey } = useContext(YjsDocContext);

  const updatedBreadcrumb = useMemo(()=>[...(props.breadcrumbs ?? []), { label: FORM_LABEL }], [props.breadcrumbs]);

  return <FormContextSetup formId={formId} base={FORM_CONTEXT}>
    <SetupPdfLoadStateContext>
      <VendorToSignInner
        {...props}
        breadcrumbs={updatedBreadcrumb}
      />
    </SetupPdfLoadStateContext>
  </FormContextSetup>;

};

const userInteraction = {
  userShouldSeeAllValidation: true
};

export const VendorToSignInner = ({ formId, parentPath = '', myPath = '', breadcrumbs, entityLogoLoadedUri }: TransactionFormProps) => {
  const navigate = useNavigate();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { snapshotData: previousData } = useContext(LineageContext);
  const { ydoc, transactionRootKey: yRootKey, transactionMetaRootKey: yMetaRootKey, formName: formCode, docName: propertyId } = useForm();

  useSigningValidation({
    ydoc,
    dataRootKeys: [yRootKey],
    metaRootKey: yMetaRootKey,
    formCode,
    formId,
    propertyId: propertyId || '',
    signingStatus: FormSigningState.Configuring
  });
  const {
    bindState: metaBindState
  } = useImmerYjs<TransactionMetaData>(ydoc, yMetaRootKey);
  const {
    bindState: rootDataBindState
  } = useImmerYjs<MaterialisedPropertyData>(ydoc, PropertyRootKey.Data);
  // We want the root state, not the snapshot data here, as the snapshot doesn't contain agency info
  const { data: agency } = rootDataBindState<Agent[]|undefined>(root=>root.agent);

  const { data: instance, update: updateInstance } = metaBindState<FormInstance>(m => FormUtil.getFormStateFromIdAlone(formId, m));
  const previewerTarget = useRef<HTMLDivElement|null>(null);
  const pdfIds = determinePreviewFiles(instance, true);
  const idTrigger = pdfIds.files.map(f=>f.id).join('');
  const previewFile = useLiveQuery(async () => {
    const workingId = pdfIds.files[0]?.id;
    if (!workingId) return undefined;
    const blob = (await FileStorage.read(workingId))?.data;
    if (!blob) return undefined;
    return URL.createObjectURL(blob);
  }, [idTrigger]);

  const snapshotId = instance?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id;
  const [snapshot, setSnapshot] = useState<null|MaterialisedPropertyData>(null);
  const baseSalespersons = agency?.map(agency=>agency.salesp || [])?.flat();
  const salespersons = useMemo(()=>{
    if (!baseSalespersons?.some(s=>s.linkedSalespersonId === sessionInfo?.agentId)) {
      return [{
        email: sessionInfo?.email,
        id: sessionInfo?.agentUuid,
        name: sessionInfo?.name,
        linkedSalespersonId: sessionInfo?.agentId
      }, ...(baseSalespersons??[])];
    }
    return baseSalespersons;
  }, [agency]);

  const messagePreviewInitiator = useMemo(()=>{
    if (!sessionInfo) return {};
    return {
      name: sessionInfo.name,
      email: sessionInfo.email
    };
  }, [sessionInfo]);

  useEffect(()=>{
    if (!snapshotId) return;
    FileStorage.read(snapshotId).then(async file=>{
      if (!file?.data) return;
      setSnapshot(JSON.parse(await file.data.text()));
    });
  }, [snapshotId]);

  const metaPath = FormUtil.getFormPath(formCode, formId);
  const focusErrList = (useSelector((state: any) => state?.validation?.focusErrList?.[propertyId ?? '']?.[yRootKey]?.[formCode])??[]).filter((errStr: string)=>{
    // This is a submitted contract, we can't do full validation on it and expect that the agent go
    // back and correct bad fields.
    if (!errStr.startsWith(ERROR_FOCUS_PREFIX)) return false;
    errStr = errStr.slice(ERROR_FOCUS_PREFIX.length);
    const uuidIncapableSplit = errStr.split(/[_]+/);
    if (uuidIncapableSplit[0] !== 'vendors') return false;
    const lastPathElem = uuidIncapableSplit[uuidIncapableSplit.length-1];
    if (!['email1', 'email2', 'phone1', 'phone2'].includes(lastPathElem)) return;
    return true;
  });

  const wizardDisplayContextState = useMemo<WizardDisplayContextType>(()=>({
    showFocusErrors: true,
    focusErrList: focusErrList || []
  }), [focusErrList]);
  const items = snapshot ? (instance?.signing?.parties || [])
    .map(signingPartyForCard({ metaPath, data: snapshot, previousData }))
    .filter(Predicate.isNotNullish)
    .filter(item=>item.partyTypeGroup === SigningPartySourceType.Vendor)
    :[];

  const {
    signingSessionWizardPropsForSidebar,
    showGeneralEmailConfig
  } = useSigningNavProps({ signing: instance?.signing, formCode, signingPartyFilter: (parties)=>parties.filter(p=>sourceTypeGroup.get(p.source.type) === SigningPartySourceType.Vendor) });

  async function handleSigning (): Promise<void> {
    let outerRes: any;
    const migrationRes = applyMigrationsV2_1<TransactionMetaData>({
      typeName: 'Property',
      doc: ydoc,
      docKey: yMetaRootKey,
      migrations: [{
        name: 'Make signing session active again',
        fn: draft=>{
          if (!sessionInfo || !sessionInfo.agentId) {
            outerRes = 'No agent ID!';
            return false;
          }
          const instance = FormUtil.getFormStateFromIdAlone(formId, draft);
          const session = instance?.signing?.session;
          if (!session) return false;
          session.contractFromPortal = { partySourceTypeRestriction: session.partySourceTypeRestriction };
          delete session.partySourceTypeRestriction;

          const { agentId, name: agentName, email: agentEmail } = sessionInfo;

          for (const party of instance?.signing?.parties??[]) {
            if (party.signedTimestamp) continue;

            const [splitType, splitAgent] = (party.typeHostComposite??'').split('_');
            let signingPartyAgent = splitAgent;

            if (!signingPartyAgent || signingPartyAgent === 'undefined') {
              signingPartyAgent = `${baseSalespersons?.[0].linkedSalespersonId??agentId}`;
            }
            const signingType = splitType;
            const isHosted = parseInt(signingType) === SigningPartyType.SignInPerson;

            if (isHosted) {
              party.typeHostComposite = `2_${signingPartyAgent}`;
              delete party.typeHostComposite_display;
            } else {
              party.typeHostComposite = `${signingType}`;
            }
            party.typeHostParty = signingPartyAgent;
          }

          const resetInitiator: SigningInitiator = {
            ...session.initiator,
            id: agentId,
            name: agentName,
            email: agentEmail
          };
          if (!session.initiator) {
            session.initiator = resetInitiator;
          } else {
            Object.assign(session.initiator, resetInitiator);
          }

          outerRes = cloneDeep(draft);

        }
      }]
    });

    if (!migrationRes) return;
    const headline = generateHeadlineFromMaterialisedData(snapshot);
    navigate(LinkBuilder.documentPath(
      { id: propertyId, nicetext: headline },
      { id: formId, nicetext: `Contract ${snapshot?.purchasers?.[0]?.fullLegalName||'offer'}` },
      false
    ));
  }
  const settlementDateString = (
    snapshot?.contractSettlement?.onDate == null
      ? null
      : snapshot?.contractSettlement?.onDate
        ? (snapshot?.contractSettlement?.date ? canonicalisers.date(snapshot?.contractSettlement?.date).display : null)
        : (snapshot?.contractSettlement?.afterCondition ? 'Conditional' : null)
  ) ?? '-';

  const conditionArr = [
    snapshot?.contractSpecial?.financeRequired && 'Finance',
    snapshot?.contractSpecial?.purchaserSaleRequired && 'Sale'
  ].filter(Predicate.isTruthy);
  const conditionString = conditionArr.length ? conditionArr.join(', ') : 'None';

  const currentPathing = useMatches();
  const contractManagementHref = currentPathing[currentPathing.length-2].pathname + '/contracts';
  const updatedBread: CrumbDefn[] = [...breadcrumbs.slice(0,-1), { label: 'Contract Management', href: contractManagementHref }, breadcrumbs[breadcrumbs.length-1]];
  const nextButton = <SigningConfigurationNextButton
    actions={[{ fn: handleSigning, label: 'Submit for Signing', waitingLabel: 'Preparing Document' }]}
  />;

  return <div className='alot-container position-relative h-100 w-100 d-flex'>
    <Allotment
      snap
    >
      <Allotment.Pane minSize={300} preferredSize={760} priority={LayoutPriority.High}>
        <ContentTitler title={breadcrumbs[breadcrumbs.length-1].label} breadcrumbs={updatedBread} flex={true} scroll={true}>
          <WizardDisplayContext.Provider value={wizardDisplayContextState}>
            <WizardStepPage label='Vendor to Sign' icon='' name='vendorToSign' >
              <p>
              Send this Offered Contract to the Vendor to sign. Because this document is already
              partially signed, any changes to the Vendor will not be saved or updated on the
              document, and will only affect emails sent or verification phone numbers sent to. If
              the identify of the Vendor needs to be changed, then a Variation to the Agency
              Agreement is required, and a new offer submitted thereafter.
              </p>
              {snapshot && <p>
                <div className='fw-bold mb-2'>Summary of Offer</div>
                <div>Offer: {canonicalisers.aud(snapshot.contractPrice?.purchasePrice).display}</div>
                <div>Deposit: {canonicalisers.aud(snapshot.contractPrice?.deposit).display}</div>
                <div>Settlement: {settlementDateString}</div>
                <div>Conditions: {conditionString}</div>
              </p>}
            </WizardStepPage>
            <FormUserInteractionContext.Provider value={userInteraction}>
              <WizardStepPage
                name='vendorSigners'
                label='Vendor'
                icon=''>
                <GroupedPartiesRow
                  items={items}
                  name='vendorSigners'
                  label='Vendor'
                  title='Vendor'
                  icon=''
                  disabled={{ name: true }}
                  salespersons={salespersons??[]}
                  noUndefinedSalesperson={true}
                  partiallySignedConfiguration={true}
                />
              </WizardStepPage>
              {showGeneralEmailConfig
                ? <WizardStepPage
                  key="signing-config"
                  name="signing-config"
                  label="Message"
                  icon="settings">
                  <SigningGeneralConfiguration
                    formCode={formCode}
                    formId={formId}
                    entityLogoLoadedUri={entityLogoLoadedUri}
                    overrideInitiator={messagePreviewInitiator}
                    nextButton={nextButton}
                  />
                </WizardStepPage>
                : <div className={'signing-configuration'}>
                  <Row className={'mx-0 mx-sm-2 mx-md-4 p-3'}>
                    <Col className={'d-flex flex-row-reverse'}>
                      {nextButton}
                    </Col>
                  </Row>
                </div>}
            </FormUserInteractionContext.Provider>
          </WizardDisplayContext.Provider>
        </ContentTitler>;
      </Allotment.Pane>
      <Allotment.Pane minSize={300}>
        <div className='previewPanel' ref={previewerTarget}>
          {previewFile && <PDFViewer
            bookmark=''
            pdfUrl={previewFile}
            useAdvancedMode={true}
            standalonePreview={true}
            filename='partially_signed.pdf'
            renderTextLayer={false}
            allowPrint={true}
            offsetRightByDragBar={true}
            toolbarBottom={<div></div>}
            toolbarRight={<div/>}
          />}
        </div>
      </Allotment.Pane>
    </Allotment>
  </div>;
};
