import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Spinner } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { BaseItem, InfiniteScrollListColumn, InfiniteScrollRowAction, LazyInfiniteTableList } from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { AgentAvatar } from '@property-folders/components/display/AgentAvatar';
import { DocumentListApi } from '@property-folders/common/client-api/documentListApi';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { useAgentTimezone } from '@property-folders/components/hooks/useAgentTimezone';
import { useFeatureFlags } from '@property-folders/components/hooks/useFeatureFlags';
import { AllDocumentStatus, DocumentType, PropertyList } from '@property-folders/contract/rest/document';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { HumanTimestampText, SupportedRelativeTimeFormatUnit } from '@property-folders/components/dragged-components/HumanTimestamp';
import { SearchFormOnSelectOption } from '@property-folders/components/dragged-components/SearchForm';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { PDFPreviewer } from '@property-folders/components/dragged-components/PDFViewer/PDFPreviewer';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import { NavigateFunction, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Predicate } from '@property-folders/common/predicate';
import { AssignAgentModal } from '@property-folders/components/modals/AssignAgentModal';
import { CloneSubscriptionDocumentModal } from '@property-folders/components/modals/CloneSubscriptionDocumentModal';
import { CreateFormModal } from '~/components/create-form/CreateFormModal';
import { RenameFormModal } from '@property-folders/components/modals/RenameFormModal';
import { MoveDocumentToFolderModal } from '@property-folders/components/modals/MoveDocumentToFolderModal';
import { TagColor } from '@property-folders/components/display/Tag';
import { RouterData } from '~/App';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { DocumentPendingStatus } from '~/components/DocumentPendingStatus';
import { generateHeadlineFromMaterialisedData, materialiseProperty } from '@property-folders/common/yjs-schema/property';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { EmailFormModal } from '~/components/EmailFormModal';
import clsJn from '@property-folders/common/util/classNameJoin';
import { archiveFormInstancesOfTypes, FormTypes, restoreArchivedForm } from '@property-folders/common/yjs-schema/property/form';
import { SearchBar } from '@property-folders/components/dragged-components/SearchBar';
import {
  FolderType,
  FormCode,
  FormCodeUnion,
  TransactionMetaData
} from '@property-folders/contract';
import { useDropzone } from 'react-dropzone';
import {
  UploadDocumentUtil, UploadFileInfo,
  useUploadedDocumentUploadV2
} from '@property-folders/components/hooks/useUploadedDocumentUpload';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { META_APPEND, PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { FileStorage, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { useInterval } from 'react-use';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';
import { FormUtil } from '@property-folders/common/util/form';
import { SearchType } from '@property-folders/components/display/SearchType';
import { SearchDocumentType } from '@property-folders/components/display/SearchDocumentType';
import { Form1UnarchiveModal } from '~/pages/forms/Form1UnarchiveModal';
import { FormContext } from '@property-folders/components/context/FormContext';
import { SetupNetStateWritingYjsDocContext } from '@property-folders/components/form-gen-util/yjsStore';
import { FormsListUploadFilesModal } from '~/components/FormsListUploadFilesModal';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { WithinPropertyUploadFilesModal } from '~/components/WithinPropertyUploadFilesModal';
import { FoldersApi } from '@property-folders/common/client-api/foldersApi';
import { FormsApi } from '@property-folders/common/client-api/formsApi';

import type { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import type { ModulesFromForm } from '@property-folders/contract/rest/forms';
import type { FolderDetails } from '@property-folders/contract/rest/folders';

const statusWithDetail = [AllDocumentStatus.PendingRemoteCompletion, AllDocumentStatus.OutForSigning, AllDocumentStatus.Signed];
function FormStatusCell(row: PropertyList & {active: boolean; setActive: () => void}) {
  const [show, setShow] = useState(false);
  const { statusDetail, active, setActive } = row;
  const shouldShowDetail = !!(statusWithDetail.includes(row.status) && statusDetail && statusDetail.Total);

  useEffect(() => {
    if (!active && show) {
      setShow(false);
    }
  }, [active, show]);

  const toggleShow = (newState?: boolean) => {
    newState = newState != undefined ? newState : !show;
    if (newState) {
      setActive();
    }
    setShow(newState);
  };

  return <div
    className={clsJn('d-flex flex-column justify-content-center w-sm-100 h-100 w-100 p-2', shouldShowDetail && 'inline-hover')}
    onClick={(e) => {
      if (shouldShowDetail) {
        toggleShow(!show);
        e.stopPropagation();
      }
    }}>
    <span className='d-block'>{getStatusBadge(row)}</span>
    <span className='d-flex flex-row small text-secondary'>
      {shouldShowDetail && <DocumentPendingStatus
        auto={!!statusDetail.Auto || !!row.propertyFormId}
        signed={statusDetail.Signed as number}
        total={statusDetail.Total as number}
        id={row.id}
        show={show}
        setShow={value => { toggleShow(value); }}
        signingDetail={row.signingDetail}
      />}
    </span>
  </div>;
}

function getStatusBadge(row: Pick<PropertyList, 'status' | 'isArchived'>) {
  let color: TagColor;
  let text: string;

  switch (row.status) {
    case AllDocumentStatus.OutForSigning:
    case AllDocumentStatus.PendingRemoteCompletion:
      color = TagColor.DRAFT;
      text = 'pending';
      break;
    case AllDocumentStatus.Signed:
    case AllDocumentStatus.Distributed:
      color = TagColor.SIGNED;
      text = 'signed';
      break;
    case AllDocumentStatus.Configuring:
      color = TagColor.DRAFT;
      text = 'configuring signing';
      break;
    case AllDocumentStatus.Draft:
    default:
      color = TagColor.DRAFT;
      text = 'draft';
      break;
  }

  const archived = row.isArchived ? ' (archived)': '';

  return <span style={{ color }}>{text}{archived}</span>;
}

function isSubscriptionDocument(row: Pick<PropertyList, 'type' | 'subscriptionDocumentId'>): row is Required<Pick<PropertyList, 'subscriptionDocumentId' | 'subscriptionFormId'>> & Omit<PropertyList, 'subscriptionDocumentId' | 'subscriptionFormId'> {
  return row.type === 'subscription' || !!(row.subscriptionDocumentId);
}

function hasArchiveFeature(row: PropertyList): boolean {
  if (isSubscriptionDocument(row)) return true;
  if (isInPropertyFolder(row)) {
    const fam = FormTypes[row.formCode]?.formFamily;
    return fam === FormCode.UploadedDocument || fam === FormCode.Form1;
  }

  return false;
}

function isInPropertyFolder(row: Pick<PropertyList, 'type'|'propertyId'|'signingPortalEnabled'>): row is Required<Pick<PropertyList, 'propertyId' | 'propertyFormId'>> & Omit<PropertyList, 'propertyId' | 'propertyFormId'> {
  return !!(row.propertyId) && !row.signingPortalEnabled;
}

function isFullySigned(row: Pick<PropertyList, 'status'>) {
  return [AllDocumentStatus.Signed, AllDocumentStatus.Distributed].includes(row.status);
}

type PropertyListRowType = PropertyList & BaseItem;

interface FormsListPageProps {
  fromNewPropertyFolder?: boolean;
  /**
   * Should expect `formId` in URL parameter.
   */
  isForm?: boolean;
  /**
   * Should expect `folderId` in URL parameter.
   */
  isFolder?: boolean;
}

export function FormsListPage({ fromNewPropertyFolder, isForm, isFolder }: FormsListPageProps) {
  const featureFlags = useFeatureFlags();
  const [urlSearchParams] = useSearchParams();
  // Can confirm type is not undefined since there is a useEffect guard to not handle undefined form values.
  const { formId: formIdFromParams, folderId: folderIdFromParams  } = useParams() as { formId?: string; folderId?: string; };
  const [formIdAsNumber, setFormIdAsNumber] = useState<number | undefined>();
  const [folderIdAsNumber, setFolderIdAsNumber] = useState<number | undefined>();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  // `null` when `{formId}` pending/not present.
  const [formModules, setFormModules] = useState<ModulesFromForm['results'] | null>(null);
  // `null` when `{folderId}` pending/not present.
  const [folderDetails, setFolderDetails] = useState<FolderDetails['results'] | null>(null);
  const loaderData = useReactRouterData<RouterData>();
  const property = materialiseProperty(loaderData.ydoc);
  const headline = generateHeadlineFromMaterialisedData(property?.data);
  const filterInitial = useMemo(() => deserialiseSearchParams(urlSearchParams), []);
  const [filter, setFilter] = useState<string>(filterInitial);
  const [documentTypeFilter, setDocumentTypeFilter] = useState<DocumentType | undefined>(undefined);
  const [showArchived, setShowArchived] = useState(false);
  const { bindState } = useImmerYjs<TransactionMetaData>(loaderData.ydoc, PropertyRootKey.Meta);
  const { data: meta } = bindState<TransactionMetaData>(s => s);
  const [highlightRows, setHighlightRows] = useState<string[]>(state?.highlight||[]);
  const [fakeRows, setFakeRows] = useState<PropertyList[]>(state?.fakeRows || []);
  const [filesPendingUpload, setFilesPendingUpload] = useState<string[]>([]);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const isOnline = useOnline();

  useEffect(() => {
    setFakeRows(state?.fakeRows || []);
  }, [state?.fakeRows]);

  //listen for changes to the sublineage docs
  meta?.sublineageRoots?.map((rootKey)=> {
    return useImmerYjs<TransactionMetaData>(loaderData.ydoc, rootKey + META_APPEND)?.bindState(b => b.formStates);
  });

  const {
    data,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    refetch
  } = DocumentListApi.useInfiniteDocumentList({
    pageNumber: 1,
    limit: 100,
    searchTerm: filter,
    propertyId: loaderData.transId,
    showArchived,
    fromNewPropertyFolder: !!fromNewPropertyFolder,
    propertyYdoc: loaderData.ydoc,
    isOnline,
    formId: formIdAsNumber,
    folderId: folderIdAsNumber,
    documentType: documentTypeFilter
  });

  useMemo(async () => {
    const localFormIdAsNumber = isForm && formIdFromParams !== undefined && !isNaN(parseInt(formIdFromParams)) ? parseInt(formIdFromParams) : undefined;
    setFormIdAsNumber(localFormIdAsNumber);

    // Do not fetch documents for invalid form id.
    if (!localFormIdAsNumber) {
      return;
    }

    const { results } = await FormsApi.getModulesFromForm({ formId: localFormIdAsNumber });
    setFormModules(results);
  }, [formIdFromParams]);

  useMemo(async () => {
    const localFolderIdAsNumber = isFolder && folderIdFromParams !== undefined && !isNaN(parseInt(folderIdFromParams)) ? parseInt(folderIdFromParams) : undefined;
    setFolderIdAsNumber(localFolderIdAsNumber);

    // Do not fetch documents for invalid folder id.
    if (!localFolderIdAsNumber) {
      return;
    }

    const { results } = await FoldersApi.getDetails({ folderId: localFolderIdAsNumber });
    setFolderDetails(results);
  }, [folderIdFromParams]);

  // Redirect guard to ensure we only handle integers in the :formId URL parameter.
  useEffect(() => { isForm && !formIdAsNumber && navigate('/forms', { replace: true }); });

  // Redirect guard to ensure we only handle integers in the :folderId URL parameter.
  useEffect(() => { isFolder && !folderIdAsNumber && navigate('/folders', { replace: true }); });

  const updateFileSyncStatus = () => {
    if (!fromNewPropertyFolder) return;
    Promise.all(data?.pages?.[0]?.results
      ?.filter(d => d?.formCode === FormCode.UploadedDocument)
      ?.map(u => u?.propertyFormId ? FileStorage.readMeta(u.propertyFormId): undefined))
      ?.then(fileInfo => setFilesPendingUpload(fileInfo?.filter(fi=>fi?.syncStatus === StorageItemSyncStatus.PendingUpload)?.map(f => f?.id)));
  };

  //poll for updated file sync status
  useInterval(() => {
    if (!filesPendingUpload?.length) return;
    updateFileSyncStatus();
  }, 2000);

  useEffect(() => {
    updateFileSyncStatus();
  }, [meta]);

  useEffect(() => {
    if (highlightRows?.length) setTimeout(()=>setHighlightRows([]), 4000);
  }, [highlightRows]);

  const items = useMemo<PropertyListRowType[]>(() => {
    if (!data?.pages?.length) return [...fakeRows];
    const seenFormIds = new Set<string>();
    const realRows = data.pages.flatMap(p=> p.results || [])
      .filter((p) => p && Predicate.isNotNull(p) && p.isArchived === showArchived)
      .map(p => {
        if (p.propertyFormId) seenFormIds.add(p.propertyFormId);
        return ({
          ...p,
          rowClass: clsJn({ 'row-disabled': p.isArchived, 'row-highlight': highlightRows.includes(p.propertyFormId) })
        });
      });
    return fakeRows
      .filter(f => !seenFormIds.has(f.propertyFormId))
      .concat(realRows);
  }, [data?.pages, highlightRows, fakeRows]);

  const timeZone = useAgentTimezone();
  const [previewUrl, internalSetPreviewUrl] = useState<string | undefined>(undefined);
  const [previewFileName, setPreviewFileName] = useState<string | undefined>(undefined);
  const renderTextLayer = useBreakpointValue({ base: false, sm: true }, true);
  const [showAssignAgentsModal, setShowAssignAgentsModal] = useState<boolean>(false);
  const [showCloneModal, setShowCloneModal] = useState<boolean>(false);
  const [showRenameFormModal, setShowRenameFormModal] = useState<boolean>(false);
  const [showMoveDocumentToFolderModal, setShowMoveDocumentToFolderModal] = useState<boolean>(false);
  const [showCreateDocumentFromFormModal, setShowCreateDocumentFromFormModal] = useState<boolean>(false);
  const [showEmailFormModal, setShowEmailFormModal] = useState<boolean>(false);
  const [workingDocument, setWorkingDocument] = useState<PropertyList | undefined>();
  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [showUnArchiveModal, setShowUnArchiveModal] = useState(false);
  const inPropertyFolder = !!loaderData.transId;
  const { newPropertyFolders, myFiles } = sessionInfo?.featureFlags || {};
  const myFilesEnabled = Boolean(newPropertyFolders && myFiles);
  const uploadEnabled = inPropertyFolder || myFilesEnabled;
  const [showFormsUploadModal, setShowFormsUploadModal] = useState<boolean>(false);
  const [showPropertyUploadModal, setShowPropertyUploadModal] = useState<boolean>(false);
  const [uploadFileInfos, setUploadFileInfos] = useState<UploadFileInfo[]>([]);
  const { instance: fileSync } = useContext(FileSyncContext);
  const onQueueProcessed = useCallback((files: File[]) => {
    if (!files.length) return;
    if (!sessionInfo) return;
    if (!fileSync) return;
    const fileInfos = files.map<UploadFileInfo>(f => ({ name: f.name, file: f }));
    if (fromNewPropertyFolder && loaderData.transId && loaderData.ydoc) {
      if (fileInfos.length === 1) {
        // just do it and navigate!
        UploadDocumentUtil.addToPropertyFolder({
          propertyId: loaderData.transId,
          doc: loaderData.ydoc,
          fileSync,
          sessionInfo,
          singleEnvelope: true,
          fileInfos
        })
          .then(added => {
            if (added.length === 1) {
              navigate(`/properties/${LinkBuilder.seoFriendlySlug(loaderData.transId, headline)}/document/${LinkBuilder.seoFriendlySlug(added[0].formId, added[0].name)}`);
              return;
            }
            setHighlightRows(added.map(f => f.formId));
          })
          .catch(console.error);
      } else {
        // show the envelope/separate selection modal.
        setUploadFileInfos(fileInfos);
        setShowPropertyUploadModal(true);
      }
    } else {
      // show the modal that asks about adding to property folder vs my files.
      setUploadFileInfos(fileInfos);
      setShowFormsUploadModal(true);
    }
  }, [loaderData.transId, !!sessionInfo, fileSync]);
  const { handleDrop, hasFiles } = useUploadedDocumentUploadV2({ onFilesPreProcessed: onQueueProcessed });

  //clear the history state so the highlight rows dont re-trigger
  useEffect(() => {
    navigate(location.pathname, { replace: true });
  }, []);

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.files && handleDrop([...event.target.files]);
  };

  const { getRootProps, getInputProps, isDragAccept, inputRef } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    accept: { 'application/pdf': [] }
  });

  const desktopColumns = useMemo<InfiniteScrollListColumn<PropertyList>[]>(() => [
    {
      label: 'Name',
      rowMajor: row => <div className='d-flex'>
        <div>
          <div className={'fw-bold d-flex align-items-center'}>
            <PropertyListMajor row={row} inPropertyFolderContext={fromNewPropertyFolder} />
            {filesPendingUpload?.includes(row.propertyFormId)
              ? isOnline
                ? <span><Spinner animation="border" className={'ms-2'} style={{ height: '1.2em', width: '1.2em' }}/></span>
                : <span><Icon name='wifi_off' /></span>
              : <></>
            }
          </div>
        </div>
      </div>,
      rowMinor: row => <PropertyListMinor row={row} inPropertyFolderContext={fromNewPropertyFolder} />
    },
    {
      label: 'Status',
      rowMajor: row => <div><FormStatusCell {...row} active={activeRow === row.id} setActive={() => setActiveRow(row.id)} /></div>,
      headerCellStyle: { width: '15%' }
    },
    {
      label: 'Owner',
      rowMajor: row => <div>
        <AgentAvatar agent={{ agentId: row.ownerId, name: row.ownerName }} fontSize='12px' />
        <span className='ms-2'>{row.ownerName}</span>
      </div>,
      headerCellStyle: { width: '20%' }
    },
    {
      label: 'Modified',
      rowMajor: row => row.locked
        ? <div><AgentAvatar agent={row.locked.agent} fontSize='12px' /><span className='ms-2 text-danger'>Locked for editing</span></div>
        : <HumanTimestampText
          timestamp={row.updateStamp}
          maxInterval={SupportedRelativeTimeFormatUnit.week}
          timeZone={timeZone}
        />,
      hoverText: row => formatTimestamp(row.updateStamp, timeZone, true),
      headerCellStyle: { width: '12%' }
    },
    {
      label: 'Created',
      rowMajor: row => formatTimestamp(row.createdStamp, timeZone, false),
      hoverText: row => formatTimestamp(row.createdStamp, timeZone, true),
      headerCellStyle: { width: '12%' }
    }
  ], [timeZone, activeRow, filesPendingUpload]);

  const rowActions: InfiniteScrollRowAction<PropertyList>[] = [
    {
      label: 'View',
      action: row => navigateToRow(row, navigate, headline, pathname, filter),
      disabled: rowData => !!rowData.locked
    },
    {
      label: 'Email',
      action: row => {
        setWorkingDocument(row);
        setShowEmailFormModal(true);
      }
    },
    {
      label: 'Preview',
      action: row => {
        if (isSubscriptionDocument(row)) {
          setPreviewFileName(`${row.name}.pdf`);
          internalSetPreviewUrl(LinkBuilder.ajaxRequest({
            action: 'generatepdf',
            DocumentID: row.subscriptionDocumentId
          })
          );
        } else {
          window.open(LinkBuilder.restApi(`/properties/${row.propertyId}/documents/${row.propertyFormId}`), '_blank');
        }
      },
      if: row => isFullySigned(row) || isSubscriptionDocument(row)
    },
    {
      label: 'Download',
      action: row => {
        if (!row.subscriptionDocumentId) return;
        const a = document.createElement('a');
        a.href = AjaxPhp.generatePdfUrl(row.subscriptionDocumentId);
        a.download = `${row.name}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      if: isSubscriptionDocument
    },
    {
      label: 'Certificate',
      action: row => {
        if (!row.subscriptionDocumentId) return;
        setPreviewFileName(`Certificate - ${row.name}.pdf`);
        internalSetPreviewUrl(AjaxPhp.generateAuditPdfUrl(row.subscriptionDocumentId));
      },
      if: isSubscriptionDocument
    },
    {
      label: 'Assign Agents',
      action: row => {
        setWorkingDocument(row);
        setShowAssignAgentsModal(true);
      },
      if: isSubscriptionDocument
    },
    {
      label: 'Move to Folder',
      action: row => {
        setWorkingDocument(row);
        setShowMoveDocumentToFolderModal(true);
      },
      if: (row) => !featureFlags.newPropertyFolders && isSubscriptionDocument(row)
    },
    {
      label: 'Rename Form',
      action: row => {
        setWorkingDocument(row);
        setShowRenameFormModal(true);
      },
      if: isSubscriptionDocument
    },
    {
      label: 'Clone',
      action: row => {
        setWorkingDocument(row);
        setShowCloneModal(true);
      },
      if: isSubscriptionDocument
    },
    {
      label: 'Archive',
      action: row => {
        if (isSubscriptionDocument(row)) {
          AjaxPhp.archiveDocument(row.subscriptionDocumentId)
            .then(() => {
              if (isInPropertyFolder(row)) {
                archiveFormHandler(row.formCode as FormCodeUnion, row.propertyFormId);
              }
              refetch();
            });
        } else if (isInPropertyFolder(row)) {
          archiveFormHandler(row.formCode as FormCodeUnion, row.propertyFormId);
        }
      },
      if: row => !row.isArchived && hasArchiveFeature(row) && row.formCode !== FormCode.Form1
    },
    {
      label: 'Unarchive',
      action: row => {
        const doUnarchive = () => {
          if (isSubscriptionDocument(row)) {
            AjaxPhp.unarchiveDocument(row.subscriptionDocumentId)
              .then(() => {
                if (isInPropertyFolder(row)) {
                  unarchiveFormHandler(row.formCode as FormCodeUnion, row.propertyFormId);
                }
                refetch();
              });
          } else if (isInPropertyFolder(row)) {
            unarchiveFormHandler(row.formCode as FormCodeUnion, row.propertyFormId);
          }
        };

        if (row.formCode === FormCode.Form1) {
          setWorkingDocument(row);
          setShowUnArchiveModal(true);
        } else {
          doUnarchive();
        }

      },
      if: row => row.isArchived && hasArchiveFeature(row)
    }
  ];

  const archiveFormHandler = (formCode: FormCodeUnion, formId: string) => {
    applyMigrationsV2<TransactionMetaData>({
      doc: loaderData.ydoc,
      docKey: PropertyRootKey.Meta,
      typeName: 'Property',
      migrations: [{
        name: `archive property form ${formCode} ${formId}`,
        fn: draft => {
          const instance = FormUtil.getFormState(formCode, formId, draft);
          if (!instance) return;
          archiveFormInstancesOfTypes(
            draft,
            [instance],
            [formCode, ...(FormTypes[formCode].archiveSiblingTypesOnCreate??[])] // Always archive self, this type property seems to only exist on Form 1s
          );
        }
      }]
    });
  };

  const unarchiveFormHandler = (formCode: FormCodeUnion, formId: string) => {
    applyMigrationsV2<TransactionMetaData>({
      doc: loaderData.ydoc,
      docKey: PropertyRootKey.Meta,
      typeName: 'Property',
      migrations: [{
        name: `unarchive property form ${formCode} ${formId}`,
        fn: draft => {
          restoreArchivedForm(draft, formCode, formId);
        }
      }]
    });
  };

  /**
   * Ensure the create button action is handled as expected on each page.
   */
  const onCreate = () => {
    switch (true) {
      case inPropertyFolder:
        navigate('create');
        break;
      case isForm:
        setShowCreateDocumentFromFormModal(true);
        break;
      case isFolder:
        navigate(`/forms/create?folderId=${folderIdAsNumber}`);
        break;
      default:
        navigate('/forms/create');
    }
  };

  const breadcrumbs = (() => {
    switch (true) {
      case fromNewPropertyFolder:
        return useMemo(() => [
          { label: 'Properties', href: '/properties/' },
          { label: headline || 'Property Overview', href: `/properties/${LinkBuilder.seoFriendlySlug(loaderData.transId, headline)}` },
          { label: '' }
        ], [headline]);
      case !!(isFolder && folderDetails):
        return [
          { label: 'Folders', href: '/folders' },
          { label: folderDetails.folderName, href: `/folders/${folderDetails.folderId}/documents` },
          { label: '' }
        ].filter((o) => Predicate.isString(o.label)) as CrumbDefn[];
      case !!(isForm && formModules):
        return [
          { label: 'Forms', href: '/forms' },
          // TODO: Will eventually link to module pages (maybe).
          { label: formModules.moduleParentName },
          { label: formModules.moduleName },
          { label: '' }
        ].filter((o) => Predicate.isString(o.label)) as CrumbDefn[];
      default:
        return undefined;
    }
  })();

  const title =
    fromNewPropertyFolder ? 'All Documents'
      : isFolder ? 'Documents'
        : isForm && formModules ? formModules.formName
          : 'All Documents';

  const createButtonTitle =
    isFolder && folderDetails ? `Create document in ${folderDetails.folderName}`
      : isForm && formModules ? `Create ${formModules.formName} document`
        : 'Add form';

  const afterTitle = <>
    <Button
      variant='primary'
      size='lg'
      className='d-flex align-items-center'
      title={createButtonTitle}
      onClick={onCreate}
    >
      <Icon name='add' variant='outlined' icoClass='me-2 fs-4' />
      Create
    </Button>
    {uploadEnabled && <Button variant='outline-secondary' size='lg' title='Upload' onClick={()=> {
      if (inputRef?.current) {
        inputRef.current.value = '';
        inputRef.current.click();
      }
    }}>Upload</Button>}
    <SearchBar onSearch={setFilter} />
    {!fromNewPropertyFolder && <SearchDocumentType
      onChange={setDocumentTypeFilter}
    />}
    <SearchType
      favourites={false}
      setShowArchived={setShowArchived}
    />
  </>;

  return <div
    {...getRootProps({
      className: clsJn('d-flex w-100 h-100 special-modal-container-outer',
        isDragAccept && 'drop-target drop-accept',
        hasFiles && 'is-uploaded')
    })}
  >
    {isDragAccept && <div className={'upload-overlay'}>Drop files to add to Property Folder</div>}
    {uploadEnabled && <input {...getInputProps({ className: 'd-none', accept: '.pdf', onChange: handleUpload })} />}

    {showPropertyUploadModal && <ErrorBoundary fallbackRender={fallback =>
      <FallbackModal
        {...fallback}
        onClose={() => setShowPropertyUploadModal(false)}
        show={showPropertyUploadModal}
      />
    }>
      <WithinPropertyUploadFilesModal
        onClose={() => setShowPropertyUploadModal(false)}
        fileInfos={uploadFileInfos}
        fallbackDoc={loaderData.ydoc}
        fallbackPropertyId={loaderData.transId}
      />
    </ErrorBoundary>}

    <ContentTitler
      title={title}
      breadcrumbs={breadcrumbs}
      afterTitle={afterTitle}
      flex={true}
      scroll={false}
    >
      <div className="flex-grow-1 mt-2" style={{ overflow: 'hidden', position: 'relative' }}>
        <LazyInfiniteTableList
          hover={true}
          storageKey="Documents"
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          items={items}
          columns={desktopColumns}
          rowHeight="85px"
          rowClick={row => !row.locked && navigateToRow(row, navigate, headline, pathname, filter)}
          rowActions={rowActions}
          containerClass=""
        />
      </div>

      {!!previewUrl && <PDFPreviewer
        url={previewUrl}
        filename={previewFileName}
        onClose={() => internalSetPreviewUrl(undefined)}
        tryMakeScrollWork={true}
        renderTextLayer={renderTextLayer}
      />}

      <ErrorBoundary fallbackRender={fallback =>
        <FallbackModal
          {...fallback}
          onClose={() => {
            setShowCloneModal(false);
            setWorkingDocument(undefined);
          }}
          show={showCloneModal}
        />
      }>
        {showCloneModal && <CloneSubscriptionDocumentModal
          show={showCloneModal}
          setShow={setShowCloneModal}
          documentId={workingDocument?.subscriptionDocumentId}
          documentName={workingDocument?.name}
          navigateToDocument={documentId => navigateToSubscriptionDocumentEdit(documentId, navigate, pathname)}
        />}
      </ErrorBoundary>

      <ErrorBoundary fallbackRender={fallback =>
        <FallbackModal
          {...fallback}
          onClose={() => {
            setShowAssignAgentsModal(false);
            setWorkingDocument(undefined);
          }}
          show={showAssignAgentsModal}
        />
      }>
        {showAssignAgentsModal && <AssignAgentModal
          show={showAssignAgentsModal}
          setShow={setShowAssignAgentsModal}
          documentId={workingDocument?.subscriptionDocumentId}
          refetch={refetch}
        />}
      </ErrorBoundary>

      {showUnArchiveModal && workingDocument && <ErrorBoundary fallbackRender={fallback=> <FallbackModal {...fallback} show={showUnArchiveModal} onClose={() => setShowUnArchiveModal(false)} />}>
        <SetupNetStateWritingYjsDocContext ydoc={loaderData.ydoc} awareness={loaderData.awareness} docName={loaderData.transId} transactionRootKey={PropertyRootKey.Data} transactionMetaRootKey={PropertyRootKey.Meta}>
          <FormContext.Provider value={{ formName: workingDocument.formCode, formId: workingDocument.propertyFormId }}>
            <Form1UnarchiveModal onClose={() => setShowUnArchiveModal(false)} />
          </FormContext.Provider>
        </SetupNetStateWritingYjsDocContext>
      </ErrorBoundary>}

      <ErrorBoundary fallbackRender={fallback =>
        <FallbackModal
          {...fallback}
          onClose={() => {
            setShowRenameFormModal(false);
            setWorkingDocument(undefined);
          }}
          show={showRenameFormModal}
        />
      }>
        <RenameFormModal
          show={showRenameFormModal}
          setShow={setShowRenameFormModal}
          documentId={workingDocument?.subscriptionDocumentId}
          name={workingDocument?.name}
          refetch={refetch}
        />
      </ErrorBoundary>

      {workingDocument && showMoveDocumentToFolderModal && <ErrorBoundary fallbackRender={fallback =>
        <FallbackModal
          {...fallback}
          onClose={() => {
            setShowMoveDocumentToFolderModal(false);
            setWorkingDocument(undefined);
          }}
          show={showMoveDocumentToFolderModal}
        />
      }>
        <MoveDocumentToFolderModal
          documentId={workingDocument?.subscriptionDocumentId}
          documentName={workingDocument.name}
          folderName={folderDetails?.folderName}
          show={showMoveDocumentToFolderModal}
          setShow={setShowMoveDocumentToFolderModal}
          refetch={refetch}
        />
      </ErrorBoundary>}

      {isForm && formIdAsNumber && showCreateDocumentFromFormModal && <ErrorBoundary fallbackRender={fallback =>
        <FallbackModal
          {...fallback}
          onClose={() => {
            setShowCreateDocumentFromFormModal(false);
          }}
          show={showCreateDocumentFromFormModal}
        />
      }>
        <CreateFormModal
          form={{ formId: formIdAsNumber }}
          onClose={() => {
            setShowCreateDocumentFromFormModal(false);
          }}
          skipConfirmation={false}
        />
      </ErrorBoundary>}

      {workingDocument && showEmailFormModal && <ErrorBoundary fallbackRender={fallback =>
        <FallbackModal
          {...fallback}
          onClose={() => {
            setShowEmailFormModal(false);
            setWorkingDocument(undefined);
          }}
          show={showEmailFormModal}
        />
      }>
        <EmailFormModal
          show={showEmailFormModal}
          setShow={setShowEmailFormModal}
          subscriptionDocumentId={workingDocument.subscriptionDocumentId}
          subscriptionFormId={workingDocument.subscriptionFormId}
          propertyId={workingDocument.propertyId}
          propertyFormId={workingDocument.propertyFormId}
          propertyFormCode={workingDocument.formCode}
          formName={workingDocument.formName}
          name={workingDocument.name}
          entityId={workingDocument.entityId}
          draft={workingDocument.status === AllDocumentStatus.Draft}
        />
      </ErrorBoundary>}

      {showFormsUploadModal && <ErrorBoundary fallbackRender={fallback =>
        <FallbackModal
          {...fallback}
          onClose={() => setShowFormsUploadModal(false)}
          show={showFormsUploadModal}
        />
      }>
        <FormsListUploadFilesModal
          onClose={() => setShowFormsUploadModal(false)}
          fileInfos={uploadFileInfos}
        />
      </ErrorBoundary>}
    </ContentTitler>
  </div>;
}

function deserialiseSearchParams(params: URLSearchParams): SearchFormOnSelectOption {
  for (const [key, value] of params.entries()) {
    try {
      switch (key) {
        case 'term':
          return value;
        case 'moduleId':
          return { moduleId: strictParsePositiveInt(value) };
        case 'productId':
          return { productId: strictParsePositiveInt(value) };
        case 'formId':
          return { formId: strictParsePositiveInt(value) };
        case 'formCode':
          return { formCode: value };
      }
    } catch { /**/ }
  }

  return '';
}

function navigateToSubscriptionDocumentEdit(documentId: number, navigate: NavigateFunction, pathname: string, filter?: string) {
  const returnPathParams = serialiseSearchParams(filter);
  // Return back to expected page state (hopefully similar behaviour to the browser history back button).
  const returnPath = returnPathParams.size ? `${pathname}?${returnPathParams.toString()}` :  pathname;

  const navigateParams = new URLSearchParams();
  navigateParams.set('DocumentID', documentId.toString());
  navigateParams.set('ReturnPath', returnPath);
  navigate(`/forms.php?${navigateParams.toString()}`);
}

export const navigateToRow = async (row: PropertyList, navigate: NavigateFunction, headline: string, pathname: string, filter?: string) => {
  // prefer to navigate to pf-wrapped sub docs
  if (isInPropertyFolder(row)) {
    const formType = FormTypes[row.formCode];
    navigate(LinkBuilder.documentPath({
      id: row.propertyId,
      nicetext: headline
    }, {
      id: row.propertyFormId,
      nicetext: formType.label
    }, {
      isSubscriptionForm: isSubscriptionDocument(row),
      folderType: row.type === DocumentType.MyFile
        ? FolderType.MyFile
        : row.type === DocumentType.Property
          ? FolderType.Property
          : undefined
    }));
    return;
  }

  if (isSubscriptionDocument(row)) {
    if (row.signingPortalEnabled && row.status && row.status !== AllDocumentStatus.Draft) {
      navigate(LinkBuilder.documentPath(
        { id: row.propertyId, nicetext: headline },
        { id: row.propertyFormId, nicetext: 'form' },
        { isSubscriptionForm: true, folderType: FolderType.Document }
      ));

      return;
    }

    if (row.locked) {
      return;
    }

    navigateToSubscriptionDocumentEdit(row.subscriptionDocumentId, navigate, pathname, filter);
  }
};

function strictParsePositiveInt(value: string): number {
  const parsed = parseInt(value, 10);
  if (!parsed) throw new Error();
  if (isNaN(parsed)) throw new Error();
  if (!isFinite(parsed)) throw new Error();
  if (parsed <= 0) throw new Error();

  return parsed;
}

function serialiseSearchParams(params: SearchFormOnSelectOption): URLSearchParams {
  if (!params) return new URLSearchParams();
  if (typeof params === 'string') return new URLSearchParams({ term: params });
  return new URLSearchParams(Object.entries(params).map(([key, value]) => [key, value.toString()]));
}

/**
 * Property Folder/All Documents:
 *     Document Type/Document Name
 *     Party 1, Party 2, ... Party n
 *
 * All Documents (Forms Created):
 *     Crystal docs
 *         Document Name
 *         Document Type [Subscription]
 *     My Files
 *         Envelope Name
 *         Party 1, Party 2, ... Party n [My Files] (4 documents)
 *     Property Folder Native docs
 *         Property Folder Name / Document Type
 *         Party 1, Party 2, ... Party n [Property Folder]
 *     Property Folder Uploaded docs
 *         Property Folder Name / Envelope Name
 *         Party 1, Party 2, ... Party n  (1 document)
 *     Property Folder Crystal docs
 *         Property Folder Name / Document Type
 *         Party 1, Party 2, ... Party n
 */
function PropertyListMajor({
  row,
  inPropertyFolderContext
}: {
  row: PropertyList,
  inPropertyFolderContext: boolean
}) {
  if (row.summary) {
    return <span>{pathLike([row.summary], row)}</span>;
  }
  if (inPropertyFolderContext) {
    return <span>{pathLike([row.formType, row.formName], row)}</span>;
  }

  if (row.type === 'MyFile') {
    return <span>{pathLike([row.name], row)}</span>;
  }

  if (isInPropertyFolder(row)) {
    return <span>{pathLike([row.name], row)}</span>;
  }

  return <span>{pathLike([row.name], row)}</span>;
}

function pathLike(items: (string | undefined)[], row: PropertyList): string {
  const result = items.filter(Predicate.isNotNull).join(' / ');
  if (!result) console.log('nopathlike', row);
  return result;
}

function PropertyListMinor({
  row,
  inPropertyFolderContext
}: {
  row: PropertyList,
  inPropertyFolderContext: boolean
}) {
  const parties = row.parties || '';
  const count = (row.envelopeCount || 1) > 1
    ? `${row.envelopeCount} documents`
    : '';
  if (inPropertyFolderContext) {
    if (row.formCode === FormCode.UploadedDocument) {
      return <PropertyListMinorInner parties={parties} count={count}/>;
    }
    return <PropertyListMinorInner parties={parties} />;
  }

  if (row.type === 'MyFile') {
    return <PropertyListMinorInner type={row.type} count={count} />;
  }

  if (isInPropertyFolder(row) && row.formCode === FormCode.UploadedDocument) {
    return <PropertyListMinorInner type={row.type} count={count} formName={row.formName} />;
  }

  return <PropertyListMinorInner type={row?.type} formName={row?.formName} />;
}

function PropertyListMinorInner({ parties, count, type, formName }: {parties?: string, count?: string, type?: PropertyList['type'], formName?: string }) {
  return <div className='d-flex flex-column'>
    {formName
      ? <span className='overflow-hidden text-overflow-ellipsis'>{formName}</span>
      : <></>}
    <div className='d-flex flex-row gap-2'>
      <Parties parties={parties} />
      {count ? <div>({count})</div> : <></>}
    </div>
    {type ? <div><DocumentTypeBadge type={type} /></div> : <></>}
  </div>;
}

function Parties({ parties }: {parties?: string}) {
  if (!parties?.trim()) return <></>;
  return <div
    className='overflow-hidden text-overflow-ellipsis'
    title={parties}
  >{parties}</div>;
}

function DocumentTypeBadge({ type }: { type: Required<PropertyList['type']> }) {
  switch (type) {
    case 'Subscription':
      return <Badge bg='subscription' >reaforms</Badge>;
    case 'MyFile':
      return <Badge bg='my-file'>SignAnything</Badge>;
    case 'Property':
      return <Badge bg='property'>Greatforms</Badge>;
  }

  return <></>;
}
