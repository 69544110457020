import React, { createContext, useEffect, useMemo, useState } from 'react';
import { AjaxPhp, GetEntitySettingsInfoResponse } from '@property-folders/common/util/ajaxPhp';
import { EntitySettingsTabConfig } from '~/pages/settings/config';
import { v4 } from 'uuid';
import { AllotmentPaneMode } from '@property-folders/components/hooks/useContentPreviewAllotmentState';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { ReaformsUserAssets } from '@property-folders/common/client-api/reaformsUserAssets';

export interface EntitySettingsContextData {
  afterBreadcrumbs?: HTMLDivElement | null;
  entityUuid?: string,
  entityPhpInfo?: {
    urlLogo?: string,
    logo?: Blob,
    urlEmailLogo?: string
    emailLogo?: Blob
    urlPurchaserHeader?: string
    purchaserHeader?: Blob,
    entityId: number,
    entities: GetEntitySettingsInfoResponse['entities'],
    reload: () => void
  },
  entityPhpInfoStatus: 'loading' | 'loaded',
  largeScreen: boolean,
  settingsGroupTabConfig?: EntitySettingsTabConfig,
  allotmentPaneMode: AllotmentPaneMode,
  setAllotmentPaneMode: (mode: AllotmentPaneMode) => void,
}
export const EntitySettingsContext = createContext<EntitySettingsContextData>({ largeScreen: false, entityPhpInfoStatus: 'loading', setAllotmentPaneMode: ()=>{} });

export function SetupEntitySettingsContext(props: React.PropsWithChildren<Omit<EntitySettingsContextData, 'entityPhpInfo' | 'entityPhpInfoStatus'>>) {
  const [entityBuster, setEntityBuster] = useState('');
  const [entityPhpInfo, setEntityPhpInfo ] = useState<EntitySettingsContextData['entityPhpInfo']>(undefined);
  const [entityPhpInfoLoading, setEntityPhpInfoLoading] = useState(true);
  const splitEnabled = useMediaQuery({ minWidth: BP_MINIMA.xl });
  const [allotmentPaneMode, setAllotmentPaneMode] = useState<AllotmentPaneMode>(splitEnabled ? AllotmentPaneMode.Both : AllotmentPaneMode.Content);

  useEffect(() => {
    if (!props.entityUuid) {
      setEntityPhpInfo(undefined);
      return;
    }

    const entityUuid = props.entityUuid;

    const ac = new AbortController();
    setEntityPhpInfoLoading(true);
    setEntityPhpInfo(undefined);
    loadEntitySettingsInfo(entityUuid, ac.signal, () => setEntityBuster(v4()))
      .then(result => {
        if (!result) return;
        if (ac.signal.aborted) return;
        setEntityPhpInfo(result);
      })
      .catch(err => console.error(err))
      .finally(() => {
        if (ac.signal.aborted) return;
        setEntityPhpInfoLoading(false);
      });

    return () => {
      ac.abort();
    };
  }, [props.entityUuid]);

  useEffect(() => {
    if (!entityBuster) return;
    if (!props.entityUuid) return;

    const ac = new AbortController();
    loadEntitySettingsInfo(props.entityUuid, ac.signal, () => setEntityBuster(v4()))
      .then(result => {
        if (!result) return;
        if (ac.signal.aborted) return;
        setEntityPhpInfo(result);
      })
      .catch(err => console.error(err))
      .finally(() => {
        if (ac.signal.aborted) return;
        setEntityPhpInfoLoading(false);
      });

    return () => {
      ac.abort();
    };
  }, [entityBuster]);

  const data = useMemo<EntitySettingsContextData>(() => {
    return {
      afterBreadcrumbs: props.afterBreadcrumbs,
      entityUuid: props.entityUuid,
      largeScreen: props.largeScreen,
      settingsGroupTabConfig: props.settingsGroupTabConfig,
      entityPhpInfo,
      entityPhpInfoStatus: entityPhpInfoLoading ? 'loading' : 'loaded',
      allotmentPaneMode,
      setAllotmentPaneMode
    };
  }, [
    props.afterBreadcrumbs,
    props.entityUuid,
    props.largeScreen,
    props.settingsGroupTabConfig,
    entityPhpInfo,
    entityPhpInfoLoading,
    allotmentPaneMode,
    setAllotmentPaneMode
  ]);

  return <EntitySettingsContext.Provider value={data}>
    {props.children}
  </EntitySettingsContext.Provider>;
}

async function loadEntitySettingsInfo(
  entityUuid: string,
  signal: AbortSignal,
  reload: () => void
): Promise<undefined | EntitySettingsContextData['entityPhpInfo']> {
  const phpPromise = AjaxPhp.getEntitySettingsInfo({ entityUuid, signal });
  const emailLogoPromise = ReaformsUserAssets.getEmailLogo({ entityUuid }).catch(()=>null); // Maybe nothing is set?
  const result = await phpPromise;
  if (signal.aborted) return undefined;
  if (!result?.success) return undefined;

  const [
    logo,
    purchaserHeader,
    emailLogo
  ] = await Promise.all([
    fetchImage(result.urlLogo),
    fetchImage(result.urlPurchaserHeader),
    emailLogoPromise.then(r=>r?.blob())
  ]);

  return {
    urlLogo: logo ? URL.createObjectURL(logo) : undefined,
    logo,
    urlEmailLogo: emailLogo ? URL.createObjectURL(emailLogo) : undefined,
    emailLogo: emailLogo,
    urlPurchaserHeader: purchaserHeader ? URL.createObjectURL(purchaserHeader) : undefined,
    purchaserHeader,
    entityId: result.entityId,
    entities: result.entities,
    reload
  };
}

async function fetchImage(url: string | undefined) {
  if (!url) return undefined;
  const r = await fetch(url);
  const contentType = r.headers.get('content-type');
  if (!contentType || !contentType.startsWith('image/')) return undefined;
  const blob = await r.blob();
  return blob.type.startsWith('image/')
    ? blob
    : undefined;
}
