import { useContext, useEffect } from 'react';
import * as Y from 'yjs';
import { YDocContentType } from '@property-folders/contract';
import { EntitySettingsEntity, EntitySettingsRootKey } from '@property-folders/contract/yjs-schema/entity-settings';
import { dispatchEntityData, entityObserve } from '~/redux/triggers/yjsEntity';
import {
  removeOtherEntityMetaEntries
} from '@property-folders/common/redux-reducers/entityMeta';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useDispatch } from 'react-redux';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';

export function EntityYDocObserver({ operateUnauthenticated, agentId, propertyFoldersEnabled }: {
  operateUnauthenticated: boolean,
  agentId: number,
  propertyFoldersEnabled: boolean
}) {
  const { instance: yManager } = useContext(YManagerContext);
  const { data: sessionInfoRaw } = AuthApi.useGetAgentSessionInfo();
  const sessionInfo = operateUnauthenticated ? undefined : sessionInfoRaw;
  const dispatch = useDispatch();

  useEffect(() => {
    const entities = sessionInfo?.entities || [];
    // For some reason without the operateUnauthenticated, this worked for Remote Signing but not
    // remote completion.
    if (operateUnauthenticated) return;
    if (!yManager) return;
    if (!agentId) return;
    if (!Array.isArray(entities)) return;
    if (!entities.length) return;

    const docList: Y.Doc[] = [];
    const entityIds: number[] = [];

    for (const entity of entities.filter(e => sessionInfo?.isGlobalAdmin || propertyFoldersEnabled || e.roles.includes('Manager'))) {
      const entityDoc = yManager.get(entity.entityUuid, YDocContentType.EntitySettings, { noCreate: true, readOnly: !(sessionInfo?.isGlobalAdmin || entity.roles.includes('Manager')) }).doc;
      const entityYMap = entityDoc.getMap<EntitySettingsEntity | Record<string, never>>(EntitySettingsRootKey.Main);
      entityYMap.observeDeep(entityObserve);
      docList.push(entityDoc);
      entityIds.push(entity.entityId);
      const currentYdocData = entityYMap.toJSON() as EntitySettingsEntity | Record<string, never>;
      // entityObserve only dispatches when a change is received, which is not immediately.
      dispatchEntityData({ ...currentYdocData, entityUuid: entity.entityUuid }, entity);
    }

    dispatch(removeOtherEntityMetaEntries({
      keepIds: entityIds
    }));

    return () => {
      docList.forEach(entry => entry.getMap(EntitySettingsRootKey.Main).unobserveDeep(entityObserve));
    };
  }, [agentId, sessionInfo, operateUnauthenticated, yManager]);

  return <></>;
}
