import { Button, Modal, Spinner } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Maybe } from '@property-folders/contract';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { ReaformsUserAssets } from '@property-folders/common/client-api/reaformsUserAssets';
import { EntitySettingsContext } from './EntitySettingsContext';
import { readAndCompressImage } from 'browser-image-resizer';
import type { Config } from 'browser-image-resizer';
import { emailLogoLimits } from '@property-folders/common/data-and-text/constants';
import { imageSize } from 'image-size';
import { MAX_SAFE_INTEGER } from 'lib0/number';

const resizeConfig: Config  = {

  maxWidth: emailLogoLimits.x,
  maxHeight: emailLogoLimits.y,
  mimeType: 'image/png'
};

export function NodeEmailSettingsImageUpload({
  label,
  hint,
  type = 'Logo'
}: {
  label?: string,
  hint?: string,
  type?: 'Logo'
}) {
  const online = useOnline();
  const { entityPhpInfo, entityPhpInfoStatus, entityUuid } = useContext(EntitySettingsContext);

  const url = (() => {
    switch (type) {
      case 'Logo':
        return typeof entityPhpInfo?.urlEmailLogo === 'string' ? entityPhpInfo?.urlEmailLogo : entityPhpInfo?.urlLogo;
    }
  })();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [newImage, setNewImage] = useState<Maybe<{ filename: string, data: Blob, objectUrl: string }>>(undefined);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const handleDrop = async (files: File[]) => {
    if (files.length !== 1) return;
    const fileSource = files[0];
    const arB = await fileSource.arrayBuffer();
    const imageDims = imageSize(new Uint8Array(arB));
    const alreadyComplies = (imageDims?.height??MAX_SAFE_INTEGER) <= emailLogoLimits.y
    && (imageDims?.width??MAX_SAFE_INTEGER) <= emailLogoLimits.x
    && ['jpg', 'png'].includes(imageDims?.type??'');
    const file = alreadyComplies
      ? fileSource
      : await readAndCompressImage(fileSource, resizeConfig);
    const fileValues: typeof newImage = {
      filename: fileSource.name,
      data: file,
      objectUrl: URL.createObjectURL(file)
    };
    setNewImage(fileValues);
  };
  const { getRootProps, getInputProps, inputRef, isDragAccept } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    multiple: false,
    accept: { 'image/jpeg': [], 'image/png': [] }
  });
  const openUploadModal = () => {
    setNewImage(undefined);
    setShowUploadModal(true);
    setProcessing(false);
  };
  const closeUploadModal = () => {
    setNewImage(undefined);
    setShowUploadModal(false);
    setProcessing(false);
  };
  const doUpload = async () => {
    if (!newImage) return;
    if (!entityUuid) return;
    setProcessing(true);
    try {
      const result = await ReaformsUserAssets.putEmailLogo({ entityUuid, data: newImage.data });

      if (result.ok || result.redirected) {
        entityPhpInfo?.reload();
        closeUploadModal();
        return;
      }
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };
  const doRemove =(resetToDefault?: boolean) => async () => {
    if (!entityUuid) return;
    setProcessing(true);
    try {
      const result = await ReaformsUserAssets.deleteEmailLogo({ entityUuid, resetToDefault });
      if (result.ok) {
        entityPhpInfo?.reload();
        setShowRemoveModal(false);
        setShowResetModal(false);
      }
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };

  if (!online) {
    return <></>;
  }

  return <>
    {label && <div className='lead'>
      <span>{label}</span>
    </div>}
    <div className='d-flex flex-column'>
      {hint && <small>{hint}</small>}
      <div className='mt-2 d-flex flex-row align-items-end gap-2'>
        <Button variant='outline-primary' onClick={openUploadModal}>Upload</Button>
        {entityPhpInfo?.urlLogo && entityPhpInfo?.urlEmailLogo && <Button variant='outline-secondary' onClick={()=>setShowResetModal(true)}>Use Document Logo</Button>}
        <Button variant='outline-danger' onClick={() => setShowRemoveModal(true)}>Remove</Button>
      </div>
      <div className='w-100 mt-2 d-flex justify-content-center align-items-center border border-1 p-2'>
        {entityPhpInfoStatus === 'loading'
          ? <Spinner animation='border' />
          : url
            ? <img src={url} style={{ height: '64px', objectFit: 'contain' }}></img>
            : <div className='fs-4 d-flex justify-content-center align-items-center' style={{ height: '64px' }}>None Set</div>}
      </div>
    </div>
    {showUploadModal && <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)} backdrop={processing ? 'static' : true}>
      <Modal.Header>
        <Modal.Title>Upload Logo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div {...getRootProps({
          className: clsJn(
            'd-flex flex-row align-items-center justify-content-center cursor-pointer drop-target',
            isDragAccept && 'drop-accept'
          ),
          style: {
            height: '100px'
          },
          onClick: () => {
            inputRef?.current?.click();
          }
        })}>
          <input {...getInputProps({ className: 'd-none', accept: 'image/jpeg,image/png' })}/>
          {newImage
            ? <div className='d-flex flex-row flex-nowrap gap-2 align-items-center justify-content-center h-100 w-100 p-2'>
              <img src={newImage.objectUrl} className='h-100' style={{ objectFit: 'contain' }} />
              <div className='d-flex flex-row align-items-center overflow-hidden wrap-text'><h5>{newImage.filename}</h5></div>
            </div>
            : <h5>Choose an image...</h5>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={closeUploadModal}>Cancel</Button>
        <SpinnerButton disabled={!newImage} processing={processing} onClick={doUpload}>Upload</SpinnerButton>
      </Modal.Footer>
    </Modal>}
    {showResetModal && <Modal show={showResetModal} onHide={() => setShowResetModal(false)} backdrop={processing ? 'static' : true}>
      <Modal.Header>
        <Modal.Title>Use Document Logo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you wish to use the Document logo for emails?
        <div className='d-flex flex-row flex-nowrap gap-2 align-items-center justify-content-center h-100 w-100 p-2'>
          <img src={entityPhpInfo?.urlLogo} className='h-100' style={{ objectFit: 'contain' }} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => setShowResetModal(false)}>Cancel</Button>
        <SpinnerButton processing={processing} onClick={doRemove(true)}>Use Document Logo</SpinnerButton>
      </Modal.Footer>
    </Modal>}
    {showRemoveModal && <Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)} backdrop={processing ? 'static' : true}>
      <Modal.Header>
        <Modal.Title>Remove Logo</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure?</Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => setShowRemoveModal(false)}>Cancel</Button>
        <SpinnerButton variant='danger' processing={processing} onClick={doRemove(false)}>Remove</SpinnerButton>
      </Modal.Footer>
    </Modal>}
  </>;
}
